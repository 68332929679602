import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/headers/header"
import Banner from "../components/banner"
import Contact from "../components/contact"
import Footer from "../components/footers/footer"


export default function contact() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Cabinet Intervaps</title>
        <meta name="description" content="Cabinet Intervaps" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Banner />
        <Header />
      </header>

      <main className="relative">
      <Contact />
      </main>
        
      <footer>
        <Footer />
      </footer>
    </div>


    
  );
}

